'use client';

import useCountry from '../../../hooks/useCountry';
import { ForCountries } from '../ForCountries';
import { getBootstrapColumnSize } from '../../../helpers/grid';
import { groupItemsByDivider } from '../../../helpers/markdown';

const Extensions = ({
    children, maxColumns = 4, minLgColumns = null, background = 'light',
    justify = 'center', outline = false,
}) => {
    const country = useCountry();

    const selectedExtensions = groupItemsByDivider(children).filter(
        (elem) => !(elem[0].type === ForCountries && !elem[0].props.countries.includes(country)),
    );

    const columnSize = getBootstrapColumnSize(maxColumns, minLgColumns ?? selectedExtensions.length);
    const columnsClasses = columnSize === 2 ? 'col-sm-6 col-md-4 col-lg-3 col-xl-2' : `col-sm-6 col-lg-${columnSize}`;
    const tileClasses = outline ? `db-white border border-${background} border-3` : `bg-${background}`;

    return (
        <div className={`row justify-content-${justify} fs-6 my-6`}>
            {selectedExtensions.map((extension, index) => (
                <div key={`extension-${index}`} className={`${columnsClasses} g-4`}>
                    <div className={`card h-100 rounded-3 ${tileClasses}`}>
                        <div className='p-4 h-100'>
                            {extension}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Extensions;

'use client';

import { useCallback, useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';

import IconInline from 'components/icons/IconInline';
import { LOGIN_MODAL_DOM_ID } from '../content/login/LoginModal';
import A from '../UI/A.client';
import { getCookie } from '../../helpers/cookies';

export default function Account() {
    const t = useTranslations('header');
    const [account, setAccount] = useState(null);
    const [isTop, setIsTop] = useState(true);

    const handleScroll = useCallback(() => {
        if (window.pageYOffset > 0) {
            if (isTop) setIsTop(false);
        } else if (!isTop) {
            setIsTop(true);
        }
    }, [isTop]);

    useEffect(() => {
        setAccount(getCookie('account_url'));

        // При скол сторінки додаємо синій бекграунд кнопці signup
        window.addEventListener('scroll', handleScroll);
        // Видаляємо слухача при анмаунті компонента
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    if (account) {
        /* eslint-disable no-restricted-globals */
        return (
            <A className="d-flex btn btn-link text-body" href={`https://${account}.${location.host}/manage`}
                target="_blank">
                <div
                    className="me-2 bg-primary text-white rounded-circle text-center border border-3 border-primary overflow-hidden"
                    style={{ width: 36, height: 36 }}
                >
                    <div className="d-ib" style={{ fontSize: 24, marginTop: 5, marginRight: 0 }}>
                        <IconInline name="building" />
                    </div>
                </div>
                <div style={{ textAlign: 'left' }}>
                    <p className="fw-bold text-primary mb-0 lh-sm">
                        {t('header.auth.myAccount')} &rarr;
                    </p>

                    <div className="lh-1 small">
                        {account}
                    </div>
                </div>
            </A>
        );
    }

    return (
        <div>
            <a href="#" type="button" className="btn btn-link me-2 fs-6" data-bs-toggle="modal"
                data-bs-target={`#${LOGIN_MODAL_DOM_ID}`}>{t('header.auth.login')}</a>
            <A href="/signup" className={`btn ${isTop ? 'btn-outline-primary' : 'btn-primary'} fs-6`}>
                <span className="d-inline-block d-xl-none">{t('header.auth.signUpShort')}</span>
                <span className="d-none d-xl-inline-block ">{t('header.auth.signUpLong')}</span>
            </A>
        </div>
    );
}

'use client';

import LinkDropdown from 'components/UI/LinkDropdown';
import { setCookie } from 'helpers/cookies';
import { LOCALE_NAMES, SELECTED_LANG } from '../../constants/i18n';

export default function LanguageDropdown({ langVersions }) {
    const translationsToDropdown = langVersions.map((version) => ({
        value: LOCALE_NAMES[version.localeISO],
        href: version.url,
        locale: version.locale,
    }));

    return (
        <LinkDropdown
            id="changeLanguage"
            items={translationsToDropdown}
            itemClasses="text-muted btn-sm"
            onLinkClick={(item) => {
                setCookie(SELECTED_LANG, item.locale);
            }}
        />
    );
}

export function groupItemsByDivider(items, divider = 'hr') {
    const groupedItems = [[]];
    items.forEach((item) => {
        if (item.type === divider) {
            groupedItems.push([]);
            return;
        }

        groupedItems[groupedItems.length - 1].push(item);
    });

    return groupedItems;
}

export function calculateReadingTime(text) {
    if (!text) {
        return 0;
    }

    const wordsPerMinute = 200;
    const numberOfWords = text.split(/\s/g).length;
    return Math.ceil(numberOfWords / wordsPerMinute);
}

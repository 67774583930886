'use client';

import { useEffect } from 'react';

export default function BootstrapOffcanvas() {
    useEffect(() => {
        /* eslint-disable-next-line wrap-iife, func-names */
        (async function () {
            const canvasEl = document.getElementById('navbarNavOffcanvas');
            const Offcanvas = (await import('bootstrap/js/dist/offcanvas')).default;
            /* eslint-disable-next-line no-new */
            new Offcanvas(canvasEl);

            /* eslint-disable-next-line prefer-arrow-callback */
            canvasEl.addEventListener('hide.bs.offcanvas', function () {
                /* eslint-disable-next-line no-unused-expressions, no-undef */
                window.Intercom && Intercom('update', { hide_default_launcher: false });
            });

            /* eslint-disable-next-line prefer-arrow-callback */
            canvasEl.addEventListener('show.bs.offcanvas', function () {
                /* eslint-disable-next-line no-unused-expressions, no-undef */
                window.Intercom && Intercom('update', { hide_default_launcher: true });
            });
        })();
    }, []);
}

exports.UA = 'ua';
exports.RU = 'ru';
exports.EN = 'en';
exports.MX = 'mx';

exports.DEFAULT_LOCALE = exports.RU;

exports.SUPPORTED_LOCALES = [exports.EN, exports.RU, exports.UA, exports.MX];

exports.I18N_DOMAINS = [
    {
        domain: 'joinposter.com',
        defaultLocale: exports.RU,
        locales: [exports.UA, exports.RU, exports.EN],
        env: 'production',
    },
    {
        domain: 'joinposter.mx',
        defaultLocale: exports.MX,
        locales: [exports.MX],
        env: 'production',
    },
];

exports.DEFAULT_DOMAIN_LIST = [
    {
        domain: 'joinposter.com',
        env: 'production',
    },
    {
        domain: 'localhost:3000',
        env: 'development',
    },
];

exports.LOCALE_STANDARD_NAMES = {
    [exports.UA]: 'uk',
    [exports.MX]: 'es-MX',
    [exports.EN]: 'en-US',
};

exports.LOCALE_NAMES = {
    'ru': 'Русский',
    'uk': 'Українська',
    'en-US': 'English',
    'es-MX': 'Español',
};

exports.PREFERRED_COUNTRIES_BY_LANG = {
    en: ['us', 'gb', 'nz', 'au'],
    ru: ['ua', 'kz', 'uz'],
    ua: ['ua'],
    pl: ['pl'],
    mx: ['mx', 'es', 'co'],
};

exports.SELECTED_LANG = 'SELECTED_LANG';

exports.MIDDLEWARE_LOCALE = 'x-middleware-request-x-next-intl-locale';

exports.COUNTRY_CODE_COOKIE = 'HTTP_GEOIP_COUNTRY_CODE';
